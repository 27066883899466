<template>
  <div>
    <b-modal
      id="modal-print-agenda"
      v-if="printNow"
      hide-footer
      no-fade
      size="lg"
      @hide="close"
    >
      <template #modal-title>
        <b-btn variant="none" @click="print"><b-icon-printer /> Imprimir</b-btn>
      </template>
      <small>clique fora para fechar</small>
      <div id="divPrint">
        <print-component
          :printNow="printNow"
          @done="donePrint"
          :titulo="`Agenda`"
          :filtros="filtro"
          :show="true"
        >
          <table class="table" style="font-size:12px">
            <tr>
              <th class="bg-light"><i class="fa fa-calendar-alt" /> Data</th>
              <td class="text-center">
                {{ lista.data | moment("DD/MM/YYYY") }}
              </td>
              <th class="bg-light"><i class="fa fa-clock" /> Horário</th>
              <td class="text-center">{{ lista.hora_inicio }}</td>
              <th class="bg-light"><i class="fa fa-store-alt" /> Local</th>
              <td class="text-center">
                {{ lista.consultorio == 1 ? "Consultório" : lista.emp }}
              </td>
            </tr>
            <tr>
              <th class="bg-light"><i class="fa fa-user-md" /> Médico</th>
              <td class="text-center" colspan="3">{{ lista.EntidadeNome }}</td>
              <th class="bg-light">
                <i class="fa fa-stethoscope" /> Especialidade
              </th>
              <td class="text-center">{{ lista.especialidadeNome }}</td>
            </tr>
            <tr>
              <td colspan="4" class="pl-0">
                <table class="table table-sm" style="font-size: 12px">
                  <tr class="bg-light">
                    <th class="text-center">
                      <i class="fa fa-clock" /> Consultas
                    </th>
                    <th class="text-center">
                      <i class="fa fa-check-square" /> Confirmados
                    </th>
                    <th class="text-center">
                      <i class="fa fa-undo" /> Retorno
                    </th>
                    <th class="text-center">
                      <i class="fa fa-user-check" /> Compareceu
                    </th>
                  </tr>
                  <tr>
                    <td class="text-center">
                      {{ lista.consultas }} / {{ lista.consulta_qtd }}
                    </td>
                    <td class="text-center">{{ lista.confirmados }}</td>
                    <td class="text-center">{{ lista.retornos }}</td>
                    <td class="text-center">{{ lista.compareceram }}</td>
                  </tr>
                </table>
              </td>
              <td colspan="2" style="font-size: 11px">
                <label>Observações</label><br />
                {{ lista.obs }}
              </td>
            </tr>
          </table>

          <table
            class="table table-sm  table-stripe table-striped"
            style="font-size: 10px"
          >
            <thead>
              <tr>
                <th class="text-center">Horário</th>
                <th>Paciente</th>
                <th class="text-center">Idade</th>
                <th class="text-center">Telefone</th>
                <th class="text-center">Agendado</th>
                <th class="text-center">Confirmado</th>
                <th class="text-center">Compareceu</th>
                <th class="text-center">Retorno</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="h in lista.horarios"
                :key="`horario_${lista.id}_${h.id}`"
                class="break-before"
              >
                <td class="text-center">{{ h.horario }}</td>
                <td class="">{{ h.paciente }}</td>
                <td class="text-center">{{ h.idade }}</td>
                <td class="text-center">
                  {{ h.telefone }}
                  <small
                    v-if="
                      h.telefone2 &&
                        h.telefone2
                          .replace('(', '')
                          .replace(')', '')
                          .replace('-', '') != ''
                    "
                  >
                    | {{ h.telefone2 }}
                  </small>
                </td>
                <td class="text-center">
                  <i class="fa fa-check" v-if="h.agendado == 1" />
                </td>
                <td class="text-center">
                  <i class="fa fa-check" v-if="h.confirmou == 1" />
                </td>
                <td class="text-center">
                  <i class="fa fa-check" v-if="h.compareceu == 1" />
                  <small v-if="h.compareceu == 1 && h.dt_compareceu">
                    <br />
                    <b-icon-clock /> {{ h.dt_compareceu | moment("HH:mm") }}
                  </small>
                </td>
                <td class="text-center">
                  <i class="fa fa-check" v-if="h.retorno == 1" />
                </td>
              </tr>
            </tbody>
          </table>
        </print-component>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PrintComponent from "../Print/PrintComponent.vue";
import AgendaLib from "../../libs/AgendasLib";
import PrinterWindowHelper from "../../helpers/PrinterWindowHelper";
import moment from "moment";
export default {
  components: { PrintComponent },
  props: {
    idAgenda: [Number, String],
  },
  mounted() {
    this.carregarRelatorio();
  },
  data() {
    return {
      printNow: false,
      lista: [],
      filtro: "",
    };
  },
  watch: {
    idAgenda: function() {
      this.carregarRelatorio();
    },
  },
  computed: {},
  methods: {
    print() {
      PrinterWindowHelper(document.getElementById("divPrint").innerHTML);
    },
    close() {
      this.$emit("close");
    },
    async donePrint() {
      this.$emit("done");
    },
    async carregarRelatorio() {
      if (this.idAgenda) {
        this.lista = await AgendaLib.get(
          "",
          "",
          "",
          "",
          "",
          false,
          this.idAgenda
        );
        if (this.lista.length > 0) {
          this.lista = this.lista[0];
          console.log("lista", this.lista);
          if (this.lista.horarios) {
            let cur = moment().format("YYYY-MM-DD");
            this.lista.horarios = this.lista.horarios.sort((a, b) => {
              return moment(cur + " " + a.horario).isBefore(
                cur + " " + b.horario
              )
                ? -1
                : 1;
            });
          }
          //   this.filtro = `Agenda ${moment(this.lista.data).format("DD/MM/YYYY")} -
          //                  Início: ${this.lista.hora_inicio} -
          //                  Local: ${this.lista.consultorio==1?'Consultório':this.lista.emp}
          //                  \r\n Médico: ${this.lista.EntidadeNome} (${this.lista.especialidadeNome})
          //                  `;

          // console.log("lista", this.lista);
          this.printNow = true;
          this.$forceUpdate();
          this.$nextTick();
          // console.log("PRINTTT");
          setTimeout(() => {
            this.$bvModal.show("modal-print-agenda");
          }, 200);
        }
      } else {
        alert("informe um id da agenda");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
